import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
} from 'react';
import { observer } from 'mobx-react-lite';

import styles from './Input.module.scss';

export type InputType = 'text' | 'password' | 'number' | 'email';
export type InputEvent = ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>;

export interface InputProps {
  type?: InputType;
  label?: string;
  placeholder?: string;
  name?: string;
  dotsClassName?: string;
  className?: string;
  required?: boolean;
  textarea?: boolean;
  value?: any;
  onChange?: (e) => void;
}

const Input = observer((props: InputProps) => {
  const [isFocused, handleFocus] = useState(false);
  const [valid, setValid] = useState(true);

  const inputRef: React.MutableRefObject<any> = useRef(null);
  const {
    type,
    label,
    placeholder,
    name,
    dotsClassName,
    className,
    required,
    value,
    onChange,
  } = props;

  useEffect(() => {
    if (inputRef && inputRef.current) {
      if (!required) {
        setValid(inputRef.current.checkValidity());
      }
    }
  }, [required]);

  const handleBlur = () => {
    handleFocus(false);
    setValid(inputRef.current.checkValidity());
  };

  const onInputChange = (e: InputEvent) => {
    if (onChange) {
      onChange(e);
    }
    if (inputRef.current.checkValidity()) {
      setValid(true);
    }
  };

  const isFocus = isFocused || value.length !== 0;

  return (
    <label className={`${styles.Container} ${className}`}>
      <span className={`${styles.Label} ${required && styles.Required} 
        ${isFocus && styles.isFocus} ${!valid && styles.LabelInvalid}`}
      >
        {label}
      </span>
      <input
        type={type}
        name={name}
        className={`${styles.Input} ${!valid && styles.Invalid}`}
        required={required}
        onBlur={handleBlur}
        onFocus={() => handleFocus(!isFocused)}
        value={value}
        onChange={onInputChange}
        placeholder={placeholder}
        ref={inputRef}
      />
      <div className={`${dotsClassName} ${styles.Dots}`} />
    </label>
  );
});


export default Input;
