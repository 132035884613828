import { KENTICO_TYPES } from 'common/consts/kentico';
import { slashesToDashes } from 'common/utils/stringMethods';
import Client from './kenticoClient';

class ViewService {
  static async fetchViewData(slug: string) {
    const slugCodeName = slashesToDashes(slug);
    try {
      const items = await Client.items()
        .type(KENTICO_TYPES.VIEW)
        .containsFilter('elements.slug', [slugCodeName])
        .depthParameter(10)
        .toPromise();
      if (items.isEmpty) {
        return null;
      }
      return items.lastItem;
    } catch (e) {
      return null;
    }
  }

  static async fetchNewsletterThankYouPageData() {
    try {
      const items = await Client.items()
        .type(KENTICO_TYPES.NEWSLETTER_THANK_YOU_PAGE)
        .toPromise();
      if (items.isEmpty) {
        return null;
      }
      return items.lastItem;
    } catch (e) {
      return null;
    }
  }

}

export default ViewService;
