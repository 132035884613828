import {
  observable,
  action,
} from 'mobx';
import root from 'window-or-global';
import delay from 'lodash/delay';
import { Navigator as NavigatorModel } from 'models';
import routingStore from 'stores/Routing.store';

class NavigatorStore {
  @observable navigators:Map<string, NavigatorModel> = new Map();

  @observable scrollY: number = 0;

  @action
  navigate(anchor: string, navigator?: NavigatorModel) {
    routingStore.history.push(anchor);
    delay(() => {
      if (navigator) {
        this.navigateToElement(navigator) 
      } else {
        root.scrollTo(0, this.scrollY);
      }
    }, 100)
  }

  @action
  goBack() {
    routingStore.history.goBack();
    root.scrollTo(0, this.scrollY);
    delay(() => {
      root.scrollTo(0, this.scrollY);
    }, 200);
  }

  @action.bound
  navigateToElement(navigator: NavigatorModel) {
    const navigatorElement = this.findNavigator(navigator.id);
    if (navigatorElement) {
      navigatorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } 
  }

  @action.bound
  addNavigator(id: string, navigatorRef: NavigatorModel) {
    this.navigators.set(id, navigatorRef);
  }

  @action.bound
  removeNavigator(id: string) {
    this.navigators.delete(id);
  }

  @action.bound
  clearNavigators() {
    this.navigators.clear();
  }

  findNavigator(id: string) {
    return this.navigators.get(id);
  }

  setScrollY(scrollY: number) {
    this.scrollY = scrollY;
  }
}

export default NavigatorStore;
