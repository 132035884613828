import React, { FunctionComponent } from 'react';
import Arrow from 'assets/download-arrow.svg';
import styles from './MoreArticlesButton.module.scss';

export interface MoreArticlesButtonProps {
  text: string;
  onClick: () => void;
}

const MoreArticlesButton: FunctionComponent<MoreArticlesButtonProps> = ({
  text, 
  onClick,
}) => (
  <div className={styles.MoreArticles}>
    <div
      className={styles.Button}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={-1}
    >
      <span>{text}</span>
      <img
        src={Arrow}
        className={styles.Image}
        alt="arrow"
      />
    </div>
  </div>
);

export default MoreArticlesButton;
