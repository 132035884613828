import React, { FunctionComponent, useCallback } from 'react';
import MicrophoneIcon from 'assets/microphone.svg';
import PlayIcon from 'assets/play.svg';
import styles from './ArticleTag.module.scss';

export enum ArticleTagType {
  Audio = "audio",
  Video = "video"
}

export interface ArticleTagProps {
  className?: string;
  type: ArticleTagType
}

const ArticleTag: FunctionComponent<ArticleTagProps> = ({ 
  className,
  type,
}) => {

  const getIconSrc = useCallback(() => {
    switch(type){
      case ArticleTagType.Audio:
        return MicrophoneIcon;
      case ArticleTagType.Video:
        return PlayIcon;
      default:
        return undefined;
    }
  }, [type])
  
  return (
    <span className={`${className} ${styles.Tag}`}>
      <img
        src={getIconSrc()}
        className={styles.Image}
        alt="icon"
      />
    </span>
  )
};

export default ArticleTag;
