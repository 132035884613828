import React, { FunctionComponent } from 'react';
import styles from './Copyright.module.scss';

export interface CopyrightProps {
  className?: string;
  text1: string;
  text2: string;
  text3: string;
}

const Copyright: FunctionComponent<CopyrightProps> = ({ text1, text2, text3, className }) => (
  <div className={`${styles.Copyright} ${className}`}>
    <span>{text1}</span>
    <span>
      {text2}
      {' '}
      <span className={styles.Bolded}>{text3}</span>
    </span>
  </div>
);

export default Copyright;