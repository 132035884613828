import { KENTICO_TYPES } from 'common/consts/kentico';
import { SortOrder } from '@kentico/kontent-delivery';
import Client from './kenticoClient';

class BlogService {
  static async fetchArticle(slug: string) {
    const items = await Client.items()
      .type(KENTICO_TYPES.BLOG_ARTICLE)
      .depthParameter(10)
      .equalsFilter('elements.slug', slug)
      .toPromise();

    if (items.isEmpty) {
      return null;
    }
    return items.lastItem;
  }

  static async fetchArticleList() {
    const request = Client.items()
      .type(KENTICO_TYPES.BLOG_ARTICLE)
      .depthParameter(0)
      .elementsParameter([
        'categories__blog_sections',
        'main_category__blog_sections',
        'primary_article__primary_article',
        'article_tag__article_tag',
        'image',
        'title',
        'subtitle',
        'lead',
        'slug',
        'external_link'
      ])
      .orderParameter('elements.publication_date', SortOrder.desc);

    const response = await request.toPromise();

    if (response.isEmpty) {
      return [];
    }

    return response.items;
  }
}

export default BlogService;
