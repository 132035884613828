import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class FooterInfoSection extends ContentItemWithResolver {
  get title(): string {
    return get(this, '_title.value', '');
  }

  get firstColumn(): string {
    return get(this, '_description1.value', '');
  }

  get secondColumn(): string {
    return get(this, '_description2.value', '');
  }
}
