import React from 'react';
import { observer } from 'mobx-react-lite';
import { Footer as Model } from 'models'
import Layout from 'components/Layout/Layout';
import HorizonalLine from 'components/HorizontalLine/HorizontalLine';
import SocialMedia from 'components/SocialMedia/SocialMedia';
import Copyright from 'components/Copyright/Copyright';
import SectionsFilter from 'blocks/BlogListing/SectionsFilter/SectionFilter';
import FooterInfoSection from './FooterInfoSection/FooterInfoSection';
import { OuterLink as OuterLinkModel } from 'models'
import OuterLink from 'blocks/OuterLink/OuterLink';
import mobileDetectHook from 'common/hooks/mobileDetectHook';
import styles from './Footer.module.scss';

export interface FooterProps {
  data: Model;
  isBlogArticle?: boolean;
}

const Footer = observer(( props: FooterProps) => {
  const { data } = props;

  const {
    filterSelectAllText,
    footerImg,
    navigator,
    socialMedia,
    footerCopyright,
    infoSection,
    email,
    links
  } = data;

  const isMobile = mobileDetectHook();

  return (
    <footer className={styles.Footer}>
      <Layout className={styles.Container}>
        <div className={styles.Wrapper}>
          <div className={styles.ImgBox}>
            <img className={styles.Img} src={data.footerImg} alt={data.linkedLogo.title}/> 
          </div>
          {infoSection && (
              <FooterInfoSection
                data={infoSection}
                className={styles.InfoSection}
              />
          )}
              <div className={styles.Links}>
                <div className={styles.Email} dangerouslySetInnerHTML={{ __html: email }}></div>
                {links.map((link: OuterLinkModel) => (
                  <p key={link.id} className={styles.Link}>
                    <OuterLink data={link} />
                  </p>
                ))}
                <SocialMedia
                  className={styles.SocialMedia}
                  title={socialMedia.title}
                  ttUrl={socialMedia.twitterUrl}
                  liUrl={socialMedia.linkedinUrl}
                  ytUrl={socialMedia.youtubeUrl}
                  fbUrl={socialMedia.facebookUrl}
                />
              </div>
          {/* <SectionsFilter
            footer
            noValueText={filterSelectAllText}
            onClick={()=>{}}
            navigator={navigator}
            className={styles.Filters}
            containerClass={styles.SectionsFiltersContainer}
          /> */}
        </div>
        {!isMobile && <HorizonalLine className={styles.Line} />}
        <div className={styles.FooterInfo}>
          {data.linkedLogo && (
            <div>
              <a href={data.linkedLogo.slug}>
                <img className={styles.Logo} src={data.linkedLogo.image} alt={data.linkedLogo.title}/> 
              </a>
            </div>
          )}
          <Copyright 
            text1={footerCopyright.text1}
            text2={footerCopyright.text2}
            text3={footerCopyright.text3}
          />
        </div>
      </Layout>
    </footer>
  );
});

export default Footer;
