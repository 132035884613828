import get from 'lodash/get';
import { InnerLink } from 'models';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class NewsletterThankYouPage extends ContentItemWithResolver {
  get title(): string {
    return get(this, '_title.value', '');
  }

  get subtitle(): string {
    return get(this, '_subtitle.value', '');
  }

  get button(): InnerLink {
    return get(this, '_button.value[0]', null);
  }
}
