import get from 'lodash/get';
import { ImageWithLink, SocialMedia } from 'models';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class HeaderSection extends ContentItemWithResolver {
  
  get logo() {
    return get(this, '_logo.value[0]', null);
  }

  get linkedLogo(): ImageWithLink {
    return get(this, '_image_with_link.value[0]', null)
  }
  
  get headingText() {
    return get(this, '_heading_text.value', '');
  }

  get subHeadingText() {
    return get(this, '_heading_text_2.value', '');
  }

  get link() {
    return get(this, '_link.value[0]', null);
  }

  get mainArticle() {
    return get(this, '_main_article.value[0]', null);
  }

  get socialMedia(): SocialMedia {
    return get(this, '_social_media.value[0]', null);
  }
}
