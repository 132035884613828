import './index.module.scss';
import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { syncHistoryWithStore } from 'mobx-react-router';
import TagManager from 'react-gtm-module';
import CookiesModal from 'components/CookiesModal/CookiesModal';
import ANCHOR from 'common/consts/anchor';
import { ROUTER_PARAM } from 'common/consts/common';
import View from 'containers/View/View';
import BlogArticle from 'containers/BlogArticle/BlogArticle';
import { useStores } from 'hooks/useStores';
import routingStore from 'stores/Routing.store';
import { slashesToDashes } from 'common/utils/stringMethods';
import NewsletterThankYouPage from 'blocks/NewsletterThankYouPage/NewsletterThankYouPage';

declare const process;

const { createBrowserHistory } = require('history');

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM
};

const App: React.FC = () => {
  const { blogStore, coreStore } = useStores();
  const browserHistory = createBrowserHistory({});
  const history = syncHistoryWithStore(browserHistory, routingStore);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const onRouteChange = (props) => {
    const currentBlogCategory = props.match.params[ROUTER_PARAM.CATEGORY];

    if (currentBlogCategory) {
      blogStore.setFilter(slashesToDashes(currentBlogCategory));
    } else {
      blogStore.clearFilter();
    }

    coreStore.setUrlHistory();
  };

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${ANCHOR.NEWSLETTER_THANK_YOU_PAGE}`}
          render={(props) => {
            onRouteChange(props);
            return <NewsletterThankYouPage {...props} />;
          }}
        />
        <Route
          exact
          path={`${ANCHOR.HOME}:${ROUTER_PARAM.CATEGORY}?`}
          render={(props) => {
            onRouteChange(props);
            return <View {...props} />;
          }}
        />
        <Route
          path={`${ANCHOR.HOME}:${ROUTER_PARAM.CATEGORY}/:${ROUTER_PARAM.SLUG}`}
          render={(props) => {
            onRouteChange(props);
            return <BlogArticle {...props} />;
          }}
        />
      </Switch>
      <CookiesModal />
    </Router>
  );
};

export default App;
