import React, { FunctionComponent } from 'react';
import styles from './Layout.module.scss';

export interface LayoutProps {
  className?: string;
  gridClassName?: string;
  layoutRef?: any;
}

const Layout: FunctionComponent<LayoutProps> = ({ children, className, gridClassName, layoutRef }) => (
  <div
    className={`${styles.OuterMargins} ${className}`}
    ref={layoutRef}
  >
    <div className={`${styles.Grid} ${gridClassName}`}>
      {children}
    </div>
  </div>
);

export default Layout;
