import BlogService from 'services/Blog.service';
import {
  observable,
  action,
  when,
  computed,
  reaction,
} from 'mobx';
import CoreStore from 'stores/Core.store';
import WindowStore from 'stores/Window.store';
import { DESKTOP_ARTICLES_PER_PAGE, MOBILE_ARTICLES_PER_PAGE } from 'common/consts/blog';
import { SUBTEXT_LISTING_CODENAMES } from 'common/consts/common';
import { KENTICO_TAXONOMY } from 'common/consts/kentico';
import BlogArticle from 'models/BlogArticle.model';

const coreStore = new CoreStore()
const windowStore = new WindowStore();

class BlogStore {
  @observable loadedArticles = new Map();

  @observable latestArticle: BlogArticle | null = null;

  @observable articleList: BlogArticle[] | any = [];

  @observable filter: string[] = [];

  @observable blogSections: Map<string, string> = new Map()

  @observable currentArticle: BlogArticle | null = null;

  @observable articleListLoaded = false;

  @observable isArticleLoaded = false;

  @observable page = 1;

  @observable articlesPerPage = DESKTOP_ARTICLES_PER_PAGE;

  @observable newsletterInEachPortion = false;

  constructor() {
    when(() => coreStore.initialCoreDataFetchDone)
      .then(this.translateBlogSections);
    this.articlesPerPage = windowStore.mediaSmallDesktop
      ? DESKTOP_ARTICLES_PER_PAGE
      : MOBILE_ARTICLES_PER_PAGE;
    reaction(() => windowStore.mediaSmallDesktop, (isDesktop) => {
      const pagesNormal = this.page / this.pagesCount;
      this.articlesPerPage = isDesktop ? DESKTOP_ARTICLES_PER_PAGE : MOBILE_ARTICLES_PER_PAGE;
      this.page = Math.floor(this.pagesCount * pagesNormal);
    });
  }

  get filteredArticleList() {
    if (this.filter.length === 0) {
      return this.articleList;
    }

    return this.articleList.filter(article => article.categories
      .some(category => this.filter.some(codename => codename === category.codename)));
  }

  @computed
  get pagedArticleList() {
    const primaryArticlesCount =
      this.filteredArticleList.filter(article => article.isPrimaryArticle).length;

    if (this.page > 0) {
      let maxIndex = (this.page * this.articlesPerPage) + (primaryArticlesCount * 2);
      if (this.newsletterInEachPortion) {
        maxIndex -= 1;
      }
      return this.filteredArticleList.slice(0, maxIndex);
    }

    let maxIndex = this.page * this.articlesPerPage;
    if (this.newsletterInEachPortion) {
      maxIndex -= 1;
    }
    return this.filteredArticleList.slice(0, maxIndex);
  }

  @computed
  get hasMoreArticles() {
    if (this.pagesCount === 0 || this.pagesCount <= this.page) {
      return false;
    }
    return true;
  }

  @computed
  get blogListingSubText() {
    return this.filter.length !== 0 &&
      this.filter[0] === SUBTEXT_LISTING_CODENAMES.INSPIRING_CONTENT;
  }

  @computed
  get blogSectionCount() {
    return this.articleList
      .map(article => article.mainCategory.codename)
      .reduce((wordsCount, words) => ({
        ...wordsCount,
        [words]: (wordsCount[words] || 0) + 1
      }), {});
  }

  @computed
  get allArticlesCount() {
    return Object.keys(this.blogSectionCount)
      .map(key => this.blogSectionCount[key])
      .reduce((acc, curr) => {
        return acc + curr;
      }, 0);
  }

  @action.bound
  translateBlogSections() {
    const { taxonomies, translations } = coreStore;
    taxonomies.get(KENTICO_TAXONOMY.BLOG_SECTIONS).forEach((sectionTaxonomy) => {
      const sectionTranslation = translations && Array.isArray(translations.blogSections)
        && translations.blogSections.find(
          st => st.blogSectionCodeName === sectionTaxonomy.codename,
        );

      const translation = sectionTranslation
        ? sectionTranslation.translation
        : sectionTaxonomy.name;
      this.blogSections.set(sectionTaxonomy.codename, translation);
    });
  }

  @action.bound
  loadArticles() {
    this.page = this.page + 1;
  }

  @computed
  get pagesCount() {
    return Math.ceil(this.filteredArticleList.length / (this.articlesPerPage - (this.newsletterInEachPortion ? 1 : 0)));
  }

  @action.bound
  setFilter(...filter: string[]) {
    if (this.filter !== filter) {
      this.filter = filter;
    }
  }

  @action.bound
  clearFilter() {
    this.filter = [];
  }

  @action.bound
  setNewsletterInEachPortion() {
    this.newsletterInEachPortion = true;
  }

  @action.bound
  async getArticleList() {
    if (!this.articleListLoaded) {
      try {
        this.articleList = await BlogService.fetchArticleList();
        this.articleListLoaded = true;
        this.latestArticle = this.articleList[0];
      } catch (e) {
        throw e;
      }
    }
  }

  @action.bound
  async getArticle(slug: string) {
    let article = this.loadedArticles.get(slug);

    if (!article) {
      try {
        article = await BlogService.fetchArticle(slug);
        this.loadedArticles.set(slug, article);
      } catch (e) {
        throw e;
      }
    }

    this.currentArticle = article;
    this.setArticleLoaded(true);
  }

  @action.bound
  setArticleLoaded(isArticleLoaded: boolean) {
    this.isArticleLoaded = isArticleLoaded;
  }
}

export default BlogStore;
