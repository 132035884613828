import React from 'react';
import { observer } from 'mobx-react-lite';
import Layout from 'components/Layout/Layout';
import CloseButton from 'components/CloseButton/CloseButton';
import RichTextParser from 'components/RichTextParser/RichTextParser';
import { useStores } from 'hooks/useStores';
import Logo from 'assets/logo.svg';

import styles from './NewsletterThankYouPage.module.scss';

const NewsletterThankYouPage = observer(() => {
  const {
    navigatorStore,
    viewStore,
  } = useStores();

  const [isShown, setShown] = React.useState(false);

  const onButtonClick = () => {
    navigatorStore.goBack();
  };

  React.useEffect(() => {
    viewStore.loadNewsletterThankYouPage();
  }, [viewStore]);

  const { newsletterThankYouPage } = viewStore;

  if (!newsletterThankYouPage) {
    return null;
  }

  return (
    <div className={styles.NewsletterThankYouPage}>
      <CloseButton
        className={styles.CloseButton}
        onClick={onButtonClick}
        onMouseEnter={() => setShown(true)}
        onMouseLeave={() => setShown(false)}
        text="close"
        isShown={isShown}
      />
      <Layout className={styles.Grid}>
        <div className={styles.LogoWrapper}>
          <img
            src={Logo}
            className={styles.Logo}
            alt="Lab Insider logo"
          />
        </div>
        <section className={styles.Section}>
          <h3 className={styles.MainHeading}>
            {newsletterThankYouPage.title}
          </h3>
          <div className={styles.SmallDescription}>
            <RichTextParser
              content={newsletterThankYouPage.subtitle}
            />
          </div>
          <button
            type="button"
            className={styles.Button}
            onClick={onButtonClick}
          >
            {newsletterThankYouPage.button.text}
          </button>
        </section>
      </Layout>
    </div>
  );
});

export default NewsletterThankYouPage;
