const SLIDER_SETTINGS = {
  variableWidth: true,
  infinite: false,
  speed: 1000,
  arrows: false,
  slidesToShow: 2,
  slidesToScroll: 2,
};

export const OTHER_ARTICLES_SLIDER = {
  infinite: false,
  speed: 1000,
  arrows: false,
  slidesToShow: 1.2,
  slidesToScroll: 1,
};

export default SLIDER_SETTINGS;
