import get from 'lodash/get';
import FooterCopyright from 'models/FooterCopyright.model';
import Navigator from 'models/Navigator.model';
import ImageWithLink from 'models/ImageWithLink.model';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import FooterInfoSection from 'models/FooterInfoSection.model';

export default class Footer extends ContentItemWithResolver {

  get filterSelectAllText(): string {
    return get(this, '_filter_select_all_text.value', '');
  }

  get footerImg(): string {
    return get(this, '_footer_img.value[0].url', '');
  }

  get infoSection(): FooterInfoSection {
    return get(this, '_info.value[0]', null);
  }

  get email(): string {
    return get(this, '_reach_us_at.value', '')
  }

  get links(): [] {
    return get(this, '_links.value', []);
  }
  
  get navigator(): Navigator {
    return get(this, '_navigator.value[0]', null);
  }

  get socialMedia() {
    return get(this, '_social_media.value[0]', null);
  }

  get linkedLogo(): ImageWithLink {
    return get(this, '_image_with_link.value[0]', null)
  }

  get footerCopyright(): FooterCopyright {
    return get(this, '_footer_copyright.value[0]', null);
  }
}
