import React from 'react';
import { observer } from 'mobx-react-lite';
// import root from 'window-or-global';
import { HeaderSection as HeaderSectionModel } from 'models';
import Layout from 'components/Layout/Layout';
import Button from 'components/Button/Button';
import Asset from 'components/Asset/Asset';
import LinkParser from 'components/LinkParser/LinkParser';
import SocialMedia from 'components/SocialMedia/SocialMedia';
// import { useStores } from 'hooks/useStores';
import Arrow from 'assets/arrow';
import styles from './HeaderSection.module.scss';

export interface HeaderSectionProps {
  data: HeaderSectionModel;
}

const HeaderSection = observer((props: HeaderSectionProps) => {
  // const { windowStore } = useStores();
  const { data } = props;
  const { socialMedia } = data;

  // const headerRef: React.MutableRefObject<any> = useRef(null);

  // const onScroll = () => {
  //   const topPosition = headerRef.current.getBoundingClientRect().top;
  //   const scrollPosition = root.scrollY;
  //   if (scrollPosition > topPosition) { 
  //       headerRef.current.classList.remove(styles.Show);
  //       headerRef.current.classList.add(styles.Hide);
  //     } else {
  //       headerRef.current.classList.remove(styles.Hide);
  //       headerRef.current.classList.add(styles.Show);
  //     }
  // };

  // useLayoutEffect(() => {
  //   root.addEventListener("scroll", onScroll);
  //   return () => root.removeEventListener("scroll", onScroll);
  // }, [windowStore]);

  // useLayoutEffect(() => {
  //   root.addEventListener("resize", onScroll);
  //   return () => root.removeEventListener("resize", onScroll);
  // });

  return (
    <React.Fragment>
      <header>
        <Layout className={styles.Container}>
          <div className={styles.Wrapper}>
            {(data.linkedLogo &&
              <div>
                <a href={data.linkedLogo.slug}>
                  <img className={styles.Logo} src={data.linkedLogo.image} alt={data.linkedLogo.title} />
                </a>
              </div>
            )}
            <SocialMedia
              ttUrl={socialMedia.twitterUrl}
              liUrl={socialMedia.linkedinUrl}
              ytUrl={socialMedia.youtubeUrl}
              fbUrl={socialMedia.facebookUrl}
              className={styles.SocialMedia}
            />
          </div>
          <h1 className={styles.HeadingText}>
            {data.headingText}
          </h1>
          <div className={styles.SubHeadingText}>
            {data.subHeadingText}
          </div>
        </Layout>
        <LinkParser
          data={data.link}
          className={styles.Link}
        >
          <Button
            className={styles.Button}
          >
            <Arrow />
          </Button>
        </LinkParser>
      </header>
    </React.Fragment>
  );
});

export default HeaderSection;
