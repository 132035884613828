import React from 'react';
import { observer } from 'mobx-react-lite';
import root from 'window-or-global';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';
import { useStores } from 'hooks/useStores';
import styles from './SocialShareButtons.module.scss';

export interface SocialShareButtonProps {
  className?: string;
  embedded?: boolean;
}

const SocialShareButtons = observer((props: SocialShareButtonProps) => {
  const {
    blogStore: {
      currentArticle,
    },
  } = useStores();

  const { className, embedded } = props;

  const shareIconProps = {
    size: embedded ? 24 : 32,
    borderRadius: 8,
    bgStyle: {
      fill: "#000"
    },
  };

  const selection = root.getSelection();
  const shareButtonClassName = embedded ? styles.EmbeddedShareButton : styles.ShareButton;

  return (
    <div className={className}> 
      {embedded && <span className={styles.Label}>Share</span>}
      <div>
        <FacebookShareButton
          resetButtonStyle={false}
          className={`${shareButtonClassName}`}
          url={root.location.href}
          quote={selection}
        >
          <FacebookIcon {...shareIconProps} />
        </FacebookShareButton>
        <LinkedinShareButton
          resetButtonStyle={false}
          className={`${shareButtonClassName}`}
          url={root.location.href}
        >
          <LinkedinIcon {...shareIconProps} />
        </LinkedinShareButton>
        <TwitterShareButton
          resetButtonStyle={false}
          className={`${shareButtonClassName}`}
          url={root.location.href}
          title={selection}
          via="LabInsider"
          hashtags={currentArticle && currentArticle.hashtags.map(hashtag => hashtag.value)}
        >
          <TwitterIcon {...shareIconProps} />
        </TwitterShareButton>
      </div>
    </div>
  );
});

export default SocialShareButtons;
