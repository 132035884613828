import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class FooterCopyright extends ContentItemWithResolver {
  get text1(): string {
    return get(this, '_text_1.value');
  }

  get text2(): string {
    return get(this, '_text_2.value');
  }

  get text3(): string {
    return get(this, '_text_3.value');
  }
}
