import * as React from 'react';
import VideoSectionModel from 'models/VideoSection.model';
import styles from './VideoSection.module.scss';

export interface VideoSectionProps {
  data: VideoSectionModel;
  className?: string;
}

const VideoSection: React.FC<VideoSectionProps> = ({ data, className }) => {
	const {
		video,
		videoUrl,
	} = data;
	
  const ref = React.useRef<HTMLElement | null>(null);
	
  React.useEffect(() => {
    if(ref.current && window.location.hash === '#video') {
      ref.current.scrollIntoView();
    }
  }, [])

	return (
		<section className={`${styles.Container} ${className}`} ref={ref} id="#video">
      {video ? (
        <video
          className={styles.Video}
          src={video.url}
          muted
          playsInline
        />
      ) : (
        <iframe 
          className={styles.Iframe} 
          src={videoUrl} 
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title='youtube video'
         >
          </iframe>
      )}
    </section>
	);
};

export default VideoSection;
