import React, { FunctionComponent } from 'react';
import YouTubeLogo from 'assets/yt';
import LinkedinLogo from 'assets/linkedin';
import TwitterLogo from 'assets/tt';
import FacebookLogo from 'assets/fb';

import styles from './SocialMedia.module.scss';

export interface SocialMediaProps {
  title?: string,
  ttUrl?: string,
  liUrl?: string,
  ytUrl?: string,
  fbUrl?: string,
  className?: string;
}

const SocialMedia: FunctionComponent<SocialMediaProps> = ({ title, ttUrl, liUrl, ytUrl, fbUrl, className }) => (
  <div className={`${className} ${styles.Container}`}>
    <div className={styles.Wrapper}>
      {title && <div className={styles.Title}>{title}</div>}
      <div className={styles.SocialMedias}>
        <a
          href={ttUrl}
          target='_blank'
          rel='noopener noreferrer nofollow'
        >
          <TwitterLogo />
        </a>
        <a
          href={liUrl}
          target='_blank'
          rel='noopener noreferrer nofollow'
        >
          <LinkedinLogo />
        </a>
        <a
          href={ytUrl}
          target='_blank'
          rel='noopener noreferrer nofollow'
        >
          <YouTubeLogo />
        </a>
        <a
          href={fbUrl}
          target='_blank'
          rel='noopener noreferrer nofollow'
        >
          <FacebookLogo />
        </a>
      </div>
    </div>
  </div>
);

export default SocialMedia;
