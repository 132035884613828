import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { BlogListing as Model } from 'models';
import ArticleBox from 'blocks/ArticleBox/ArticleBox';
import Newsletter from 'blocks/Newsletter/Newsletter';
import Layout from 'components/Layout/Layout';
import Button from 'components/Button/Button';
import mobileDetectHook from 'common/hooks/mobileDetectHook';
import { useStores } from 'hooks/useStores';
import SectionsFilter from './SectionsFilter/SectionFilter';
import Arrow from 'assets/arrow';
import styles from './BlogListing.module.scss';

export interface BlogListingProps {
  data: Model;
  location?: any;
}

const BlogListing = observer((props: BlogListingProps) => {
  const [loadMore, setLoadMore] = React.useState(false);
  const { blogStore, coreStore } = useStores();

  const {
    data: {
      filterSelectAllText,
      loadMoreText,
      navigator,
      subtext,
      newsletterInEachPortion,
    },
  } = props;

  useEffect(() => {
    if (newsletterInEachPortion) {
      blogStore.setNewsletterInEachPortion();
    }
    blogStore.getArticleList();
  }, [blogStore, newsletterInEachPortion]);

  const onArticleLoad = useCallback(() => {
    blogStore.loadArticles();
    setLoadMore(true);
  }, [blogStore])

  const {
    data,
    initialCoreDataFetchDone
  } = coreStore;

  const isMobile = mobileDetectHook();

  const {
    articleListLoaded,
    pagedArticleList,
    hasMoreArticles,
    blogListingSubText,
  } = blogStore;

  return (
    <section className={styles.BlogListing}>
      <Layout className={styles.Grid}>
        <div className={styles.FiltersWrapper}>
          <SectionsFilter
            noValueText={filterSelectAllText}
            onClick={()=>{}}
            navigator={navigator}
            carousel
          />
        </div>
        {blogListingSubText && (
          <p className={styles.SubText}>{subtext}</p>
        )}
        <section className={styles.ArticleBoxesContainer}>
          {articleListLoaded && pagedArticleList.map(article => (
            <ArticleBox
              data={article}
              key={article.system.id}
              className={article.isPrimaryArticle && styles.PrimaryArticleBox}
              activeArticle={loadMore}
            />
          ))}
          {(!isMobile
            && initialCoreDataFetchDone 
            && data.newsletter 
            && (newsletterInEachPortion 
            || (!hasMoreArticles && !newsletterInEachPortion)))
            && <Newsletter data={data?.newsletter} isBlogItem />
          }
        </section>
      </Layout>
      {hasMoreArticles && (
        <div className={styles.LoadMoreContainer}>
          <Button
            className={styles.LoadMoreButton}
            onClick={onArticleLoad}
          >
            {loadMoreText}
          </Button>
          <div className={styles.ArrowWrapper}>
            <Arrow />
          </div>
        </div>
      )}
      {initialCoreDataFetchDone && data.newsletter && (
        <Newsletter data={data.newsletter} />
      )}
    </section>
  );
});

export default withRouter(BlogListing);
