import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import root from 'window-or-global';
import RichTextParser from 'components/RichTextParser/RichTextParser';
import Input from 'components/Input/Input';
import Checkbox from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';
import Layout from 'components/Layout/Layout';
import CloseButton from 'components/CloseButton/CloseButton';
import ANCHOR from 'common/consts/anchor';
import { useStores } from 'hooks/useStores';
import NewsletterIcon from 'assets/newsletter.svg';

import { Newsletter as NewsletterModel } from 'models';
import { NEWSLETTER_FORM_FIELDS, STORAGE_KEYS } from 'common/consts/newsletter';

import styles from './Newsletter.module.scss';

export interface NewsletterProps {
  data: NewsletterModel;
  className?: string;
  isPopup?: boolean;
  isBlogItem?: boolean;
}

export interface IProcessingData {
  processing?: boolean;
}

const FORM_ACTION = "https://tenderhut.eu/add_subscriber.html";
const CAMPAIGN_ID = "1";
const THANK_YOU_URL = `${root.document.location.origin}${ANCHOR.NEWSLETTER_THANK_YOU_PAGE}`

const Newsletter = observer((props: NewsletterProps) => {
  const [email, setEmail] = useState<string>('');
  const [isShown, setShown] = useState<boolean>(false);
  const [isNewsletterVisible, setNewsletterVisibility] = useState<boolean>(false);
  const [processingDataChecked, setProcessingData] = useState<IProcessingData>({});
  const [isFormEmpty, setInputForm] = useState<boolean>(true);

  const {
    navigatorStore
  } = useStores();

  const { isPopup } = props;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleProcessingDataChange = useCallback(({
    target: { name, checked },
  }) => {
    setProcessingData(prev => ({
      ...prev,
      [name]: checked,
    }));
  }, []);

  const handleScroll = useCallback(() => {
    const shouldShowNewsletter = root.scrollY > root.document.body.offsetHeight/2 - root.innerHeight;
    if (shouldShowNewsletter) {
      setNewsletterVisibility(true);
    }
  }, []);

  const hideNewsletter = () => {
    root.removeEventListener("scroll", handleScroll);
    sessionStorage.setItem(STORAGE_KEYS.NEWSLETTER, 'true');
    setNewsletterVisibility(false);
  };

  useEffect(() => {
    if (!isPopup) {
      return;
    }
    const newsletter = sessionStorage.getItem(STORAGE_KEYS.NEWSLETTER);

    if (!newsletter) {
      root.addEventListener("scroll", handleScroll);
      return () => root.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll, isPopup]);

  if (isPopup && !isNewsletterVisible) {
    return null;
  }

  const {
    data: {
      headerText,
      smallDescription,
      emailLabel,
      processingDataCheckBoxText,
      commercialInfoCheckBoxText,
      directMarketingCheckBoxText,
      signInText
    },
  } = props;

  return (
    <Layout 
      className={`
        ${props.isBlogItem ? styles.BlogItemContainer : styles.MainContainer} 
        ${isPopup ? styles.Popup : ''}
      `}
      gridClassName={`
        ${props.isBlogItem ? styles.Grid : ''}
      `}
    >
      <div className={styles.InnerContainer}>
        {isPopup && <CloseButton
          className={styles.CloseButton}
          onClick={hideNewsletter}
          onMouseEnter={() => setShown(true)}
          onMouseLeave={() => setShown(false)}
          text="close"
          isShown={isShown}
        />}
        <div className={styles.TextContainer}>
          <h3 className={styles.MainHeading}>
            {headerText}
          </h3>
          <div className={styles.SmallDescription}>
            <RichTextParser
              content={smallDescription}
            />
          </div>
        </div>
        <form
          className={styles.Form}
          onInput={() => setInputForm(false)}
          action={FORM_ACTION}
          acceptCharset="utf-8" 
          method="post"
        >
          <div className={styles.Input}>
            <img
              src={NewsletterIcon}
              className={styles.Image}
              alt="newsletter"
            />
            <Input
              type="email"
              name="email"
              label={emailLabel}
              value={email}
              className={styles.InputField}
              dotsClassName={styles.Dots}
              required
              onChange={handleChange}
            />
          </div>
          <div className={isFormEmpty
            ? styles.CheckboxHidden
            : styles.CheckboxVisible}
          >
            {processingDataCheckBoxText && (<Checkbox
              label={processingDataCheckBoxText}
              name={NEWSLETTER_FORM_FIELDS.PROCESSING}
              checked={processingDataChecked[NEWSLETTER_FORM_FIELDS.PROCESSING]}
              onChange={handleProcessingDataChange}
              required
            />)}
            {commercialInfoCheckBoxText && (<Checkbox
              label={commercialInfoCheckBoxText}
              name={NEWSLETTER_FORM_FIELDS.COMMERCIAL}
              checked={processingDataChecked[NEWSLETTER_FORM_FIELDS.COMMERCIAL]}
              onChange={handleProcessingDataChange}
              required
            />)}
            {directMarketingCheckBoxText && (<Checkbox
              label={directMarketingCheckBoxText}
              name={NEWSLETTER_FORM_FIELDS.DIRECT_MARKETING}
              checked={processingDataChecked[NEWSLETTER_FORM_FIELDS.DIRECT_MARKETING]}
              onChange={handleProcessingDataChange}
              required
            />)}
          </div>
          <input type="hidden" name="campaign_token" value={CAMPAIGN_ID} />
      	  <input type="hidden" name="thankyou_url" value={THANK_YOU_URL} />
          <Button 
            className={styles.Button} 
            onClick={() => {
              navigatorStore.setScrollY(root.scrollY);
            }}
          >
            {signInText}
          </Button>
        </form>
      </div>
    </Layout>
  );
});

export default Newsletter;
