import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class SocialMedia extends ContentItemWithResolver {
  
  get title() {
    return get(this, '_title.value', '');
  }

  get twitterUrl() {
    return get(this, '_twitter_url.value', '');
  }

  get linkedinUrl() {
    return get(this, '_linkedin_url.value', '');
  }

  get youtubeUrl() {
    return get(this, '_youtube_url.value', '');
  }

  get facebookUrl() {
    return get(this, '_facebook_url.value', '');
  }
}
