declare const process

export const PROJECT_ID = process.env.REACT_APP_KENTICO_PROJECT_ID || '';
export const PREVIEW_API_KEY = process.env.REACT_APP_KENTICO_PREVIEW_API_KEY || '';

export enum KENTICO_TAXONOMY {
  BLOG_SECTIONS = 'blog_sections',
  SLUG = 'slug',
}

export const KENTICO_TYPES = {
  CORE: 'core',
  VIEW: 'view',
  NAVBAR: 'navbar',
  FOOTER_INFO_SECTION: 'footer_info_section',
  FOOTER: 'footer',
  HEADER_SECTION: 'header_section',
  SEO: 'seo',
  BLOG_LISTING: 'blog_listing',
  BLOG_ARTICLE: 'blog_article',
  TRANSLATIONS: 'translations',
  BLOG_SECTION_TRANSLATION: 'blog_section_translation',
  VIDEO_SECTION: 'video_section',
  INNER_LINK: 'inner_link',
  OUTERLINK: 'outerlink',
  NAVIGATOR: 'navigator',
  PHONE_LINK: 'phonelink',
  CONTACT_SECTION: 'contact_section',
  INFO_COLUMN: 'info_column',
  IMAGE_WITH_LINK: 'image_with_link',
  MAIN_ARTICLE: 'main_article',
  NEWSLETTER: 'newsletter',
  AUDIO_SECTION: 'audio_section',
  HASHTAG: 'hashtag',
  SOCIAL_MEDIA: 'social_media',
  FOOTER_COPYRIGHT: 'footer_copyright',
  NEWSLETTER_THANK_YOU_PAGE: 'newsletter_thank_you_page'
};
