import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class ImageWithLink extends ContentItemWithResolver {
    get title(): string {
        return get(this, '_title.value', '')
    }
    
    get image(): string {
        return get(this, '_image.value[0].url', null)
    }

    get slug(): string {
        return get(this, '_slug.value[0].name', '')
    }
}