import { ContentItem } from '@kentico/kontent-delivery';
import { KENTICO_TYPES } from 'common/consts/kentico';
import { PODCAST_LINKS } from 'common/consts/common';
import spotify from 'assets/spotify.svg'
import apple from 'assets/podcasts.svg';
import styles from './BlogArticleRichTextParser.module.scss';

export interface RichTextComponentResolverProps {
	item: ContentItem;
}

const renderIcon = (item) => {
  switch(item.articleLinkCodename) {
    case PODCAST_LINKS.SPOTIFY:
      return spotify;
    case PODCAST_LINKS.APPLE:
      return apple;
  }
  return '';
}

const outerLinkComponentResolver = (item) => {
  return (
    `<div class="${styles.Container}">
      <a href="${item.link}" class="${styles.Link}">
        <img
          src="${renderIcon(item)}"
          alt="podcast"
        />
        <span>${item.text}</span>
      </a>
     </div>`
  );
}

const newsletterResolver = (item) => {
  return (
    `<object
      headerText=${JSON.stringify(item.headerText)}
      smallDescription=${JSON.stringify(item.smallDescription)}
      emailLabel=${JSON.stringify(item.emailLabel)}
      processingDataCheckBoxText=${JSON.stringify(item.processingDataCheckBoxText)}
      commercialInfoCheckBoxText=${JSON.stringify(item.commercialInfoCheckBoxText)}
      directMarketingCheckBoxText=${JSON.stringify(item.directMarketingCheckBoxText)}
      signInText=${JSON.stringify(item.signInText)}
      signErrorText=${JSON.stringify(item.signErrorText)}
      signSuccessText=${JSON.stringify(item.signSuccessText)}
      signingInText=${JSON.stringify(item.signingInText)}
      constructor="${item.system.type}"
    />`
  )
}

const richTextComponentResolver = (item) => {
  switch(item.system.type) {
    case KENTICO_TYPES.OUTERLINK:
      return outerLinkComponentResolver(item);
    case KENTICO_TYPES.NEWSLETTER:
      return newsletterResolver(item);
    default:
      return ''
  }
}

export default richTextComponentResolver;